

.special_icon_wrapper{
  background: white;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  right: 0.8rem;
  top: 0.4rem;
}