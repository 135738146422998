.container{
  display: flex;
  padding: 1rem;
  align-items: center;
  margin-top: -8px;
  gap: 0.5rem;
  min-width: 200px;
}

.name {
  font-size: 18px;
}

.role{
  font-size: 11px;
  opacity: 60%;
  margin-left: 0.1rem;
}