.uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.imageItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cancel_upload {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}
