.dialog{
}

.top_toolbar{
    display: flex;
    margin: 0 1rem;
    justify-content: space-between;
    align-items: center;
}

.close_icon{
    height: 25px;
}

.mainContainer{
    height: 550px;
    width: 1000px;
    margin: 2rem 0;
}

.mainForm{   
    margin: 1rem;
}

.mainForm > div{   
    margin-top: 1rem;
}

.button{
    margin: 1rem;
    position: absolute;
}

.error_text{
    color: #d32f2f;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
}

.lang_icon {
    width: 30px;
    height: 30px;
}

.lang_selector{
    width: 80px !important;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}