.main_container {
  margin: 1rem 2rem;
  width: 100%;
}

.datagrid_container {
  margin-top: 2rem;
}

.thumbnail {
  object-fit: cover;
}

.header {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.9;
}

.headerTitle {
  text-align: center;
}

.headerName {
  color: var(--color-main);
}

.toolbar {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}
