.uploader{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
}

.imageItem{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image_upload{
    display: flex;
    gap: 1rem;
    align-items: center;
}

.thumbnail{
    object-fit: cover;
    width: 50px;
    height: 50px;
    border-radius: 10rem;
}